import {
	UPDATE_API_STATUS,
	UPDATE_MENU_KEY,
	CURRENT_SCREEN_TITLE,
	SEARCH_BOTS,
	RESET_COMMON,
	GET_CRM_SCHEMA,
	SAVE_CRM_SCHEMA,
	UPDATE_CRM_SCHEMA,
	SAVE_SELECTED_BOT
} from "../constants/common";

const initState = {
	menu: "",
	submenu: "",
	apiStatus: { status: "", message: "" },
	title: "",
	searchBotText: "",
	crmSchema: {},
	selectedBot: {}
};

const common = (state = initState, action) => {
	switch (action.type) {
		case UPDATE_MENU_KEY:
			return {
				...state,
				menu: action.payload,
			};
		case RESET_COMMON:
			return {
				menu: "",
				submenu: "",
				apiStatus: { status: "", message: "" },
				title: "",
				searchBotText: "",
				crmSchema: {},
				selectedBot: {}
			};
		case UPDATE_API_STATUS:
			return {
				...state,
				apiStatus: action.payload,
			};
		case CURRENT_SCREEN_TITLE:
			return {
				...state,
				title: action.payload,
			};
		case SEARCH_BOTS:
			return {
				...state,
				searchBotText: action.payload,
			};
		case GET_CRM_SCHEMA:
			return {
				...state
			}
		case SAVE_CRM_SCHEMA:
			return {
				...state,
				crmSchema: { ...action.payload.schema }
			}
		case UPDATE_CRM_SCHEMA:
			return {
				...state
			}
		case SAVE_SELECTED_BOT:
			return {
				...state,
				selectedBot: { ...action.payload }
			}
		default:
			return state;
	}
};

export default common;
