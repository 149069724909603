export const SHOW_LOADING = 'SHOW_LOADING';
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS';
export const NEW_TEAM_FAIL = 'NEW_TEAM_FAIL';
export const CREATE_NEW_TEAM = 'CREATE_NEW_TEAM';
export const UPDATE_NEW_TEAM = 'UPDATE_NEW_TEAM';
export const GET_TEAM_LIST = 'GET_TEAM_LIST';
export const LOAD_USERS = 'LOAD_USERS';
export const CANNED_MESSAGE_LIST_SUCCESS = 'CANNED_MESSAGE_LIST_SUCCESS';
export const LOAD_CANNED_MESSAGE = 'LOAD_CANNED_MESSAGE';
export const CREATE_CANNED_MESSAGE = 'CREATE_CANNED_MESSAGE';
export const UPDATE_CANNED_MESSAGE = 'UPDATE_CANNED_MESSAGE';
export const CANNED_MESSAGE_CURRENT = 'CANNED_MESSAGE_CURRENT';
export const LOAD_CANNED_MESSAGE_CURRENT = 'LOAD_CANNED_MESSAGE_CURRENT';
export const CREATE_BUSINESS_HOUR = 'CREATE_BUSINESS_HOUR';
export const UPDATE_BUSINESS_HOUR = 'UPDATE_BUSINESS_HOUR';
export const BUSINESS_HOUR_CURRENT = 'BUSINESS_HOUR_CURRENT';
export const BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM = 'BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM';
export const LOAD_BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM = 'LOAD_BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM';
export const LOAD_BUSINESS_HOUR_CURRENT = 'LOAD_CANNED_MESSAGE_CURRENT';
export const GET_BUSINESS_HOUR_LIST = 'GET_BUSINESS_HOUR_LIST';
export const LOAD_BUSINESS_HOUR_LIST =  'LOAD_BUSINESS_HOUR_LIST';
export const CANNED_GROUP_LIST_SUCCESS = 'CANNED_GROUP_LIST_SUCCESS';
export const LOAD_CANNED_GROUP = 'LOAD_CANNED_GROUP';
export const CREATE_CANNED_GROUP = 'CREATE_CANNED_GROUP';
export const UPDATE_CANNED_GROUP = 'UPDATE_CANNED_GROUP';
export const UPDATE_ORG = 'UPDATE_ORG';
export const GET_ORG = 'GET_ORG';
export const SAVE_ORG = 'SAVE_ORG';
export const GET_SETTING = 'GET_SETTING';
export const SAVE_SETTING = 'SAVE_SETTING';
export const UPDATE_SETTING = 'UPDATE_SETTING';
export const CREATE_BULK_USER = 'CREATE_BULK_USER';
export const GET_BULK_USER = 'GET_BULK_USER';
export const SAVE_BULK_USER = 'SAVE_BULK_USER';
export const GET_USER_TEAM = 'GET_USER_TEAM';
export const SAVE_USER_TEAM = 'SAVE_USER_TEAM';
export const UPDATE_USER = 'UPDATE_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CREATE_CSAT_CONFIG = 'CREATE_CSAT_CONFIG';
export const GET_CSAT_CONFIG = 'GET_CSAT_CONFIG';
export const SAVE_CSAT_CONFIG = 'SAVE_CSAT_CONFIG';
export const GET_ACCESS = "GET_ACCESS";
export const UPDATE_ACCESS = "UPDATE_ACCESS";
export const SAVE_ACCESS = "SAVE_ACCESS";
export const GET_TAGS = "GET_TAGS";
export const ADD_TAGS = "ADD_TAGS";
export const UPDATE_TAGS = "UPDATE_TAGS";
export const SAVE_TAGS = "SAVE_TAGS";
export const ADD_SUB_TAGS = "ADD_SUB_TAGS";
export const UPDATE_SUB_TAGS = "UPDATE_SUB_TAGS";
export const GET_USER_TEAM_METADATA = "GET_USER_TEAM_METADATA";
export const SAVE_USER_TEAM_METADATA = "SAVE_USER_TEAM_METADATA";
export const RESET_USERSANDTEAMS = "RESET_USERSANDTEAMS"