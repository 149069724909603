const local = {
    API_ENDPOINT_URL: "https://gateway.konverse.ai",
    // strophe --  conn string, domain, admin
    strophe: {
        connectionString: "wss://omega.konverse.ai/xmpp",
        domain: "omega.konverse.ai",
        admin: "localadmin@omega.konverse.ai",
    },
};

const dev = {
    API_ENDPOINT_URL: "https://gateway.konverse.ai",
    strophe: {
        connectionString: "wss://omega.konverse.ai/xmpp",
        domain: "omega.konverse.ai",
        // admin: "devadmin@omega.konverse.ai",
        admin: "admin@omega.konverse.ai",
    },
    ONESIGNAL_APP_ID: "685de1cc-4a05-4fbf-b394-2051649b9f66",
    ONESIGNAL_APP_CONFIG: {},
    // FRONTEND_URL: "http://localhost:3000/",
    // API_ENDPOINT_URL: 'http://localhost:7200',
    // API_ENDPOINT_URL: "https://gateway.konverse.ai",
    PUBLIC_URLS: ["/login", "/signup", "/verify", "/forgot-password"],
    TEST_BOT_URL: "https://dev-widget-1907.web.app/chat/",
    FACEBOOK_APP_ID: "144976537777706",
    FACEBOOK_APP_SCOPE:
        "pages_show_list, pages_messaging, instagram_basic, instagram_manage_messages, pages_read_engagement, pages_manage_metadata, public_profile",
    GRAPH_API_VERSION: "v11.0",
};

const prod = {
    API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL,
    strophe: {
        connectionString: process.env.REACT_APP_STROPHE_CONNECTION_STRING,
        domain: process.env.REACT_APP_STROPHE_DOMAIN,
        admin: process.env.REACT_APP_STROPHE_ADMIN,
    },
    ONESIGNAL_APP_ID: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
    ONESIGNAL_APP_CONFIG: {
        notifyButton: {
            enable: true,
        },
    },
    FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
    PUBLIC_URLS: ["/login", "/signup", "/verify", "/forgot-password"],
    TEST_BOT_URL: process.env.REACT_APP_TEST_BOT_URL,
    FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
    FACEBOOK_APP_SCOPE: process.env.REACT_APP_FACEBOOK_APP_SCOPE,
    GRAPH_API_VERSION: process.env.REACT_APP_GRAPH_API_VERSION,
};

const test = {
    API_ENDPOINT_URL: "https://gateway.konverse.ai",
    strophe: {
        connectionString: "wss://omega.konverse.ai/xmpp",
        domain: "omega.konverse.ai",
        admin: "localadmin@omega.konverse.ai",
    },
};

const getEnv = () => {
    switch (process.env.NODE_ENV) {
        case "localhost":
            return local;
        case "development":
            return dev;
        case "production":
            return prod;
        case "test":
            return test;
        default:
            break;
    }
};

export const env = getEnv();
