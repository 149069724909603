import {
	SHOW_LOADING, SET_NODE, GET_BOT_FLOW, SET_BOT_FLOW, GET_BOT_ACCOUNTS, SET_BOT_ACCOUNTS, SET_STORY, SAVE_BOT_ACCOUNTS, RESET_BOTFLOW
} from '../constants/BotFlow';

export const setNode = (nodes) => {
	nodes = removeOrphanConnectors(nodes);
	let data = {
		type: SET_NODE,
		payload: nodes
	}
	return data
};

export const setStory = (story) => {
	let data = {
		type: SET_STORY,
		payload: story
	}
	return data
};

const removeOrphanConnectors = (nodes) => {
	let finalNodes = [];
	let finalConnectors = [];
	nodes.map(connector => {
		if (connector.source) {
			let foundSource = false;
			let foundTarget = false;
			nodes.map(node => {
				if (!node.source) {
					if (node.data) {
						if (node.data.id == connector.source || node.id == connector.source) {
							if (node.data.reply.type == "quickReply") {
								node.data.reply[node.data.reply.type].buttons.map(button => {
									if (button.id == connector.sourceHandle) {
										foundSource = true;
									}
								})
							} else {
								foundSource = true;
							}
						} else if (node.data.id == connector.target || node.id == connector.target) {
							foundTarget = true;
						} else {
							// console.log(node);
							// debugger;

						}
					} else {
						console.log(node);
						//debugger;
					}

				}
			});
			if (foundSource && foundTarget) {
				finalConnectors.push(connector);
			} else {
				// console.log("CONNECTOR REMOVED", connector);
			}
		} else {
			finalNodes.push(connector);
		}
	})
	return finalNodes.concat(finalConnectors);
}

export const showLoading = () => {
	return {
		type: SHOW_LOADING,
	};
};

export const getBotFlow = (nodes) => {
	return {
		type: GET_BOT_FLOW,
		payload: nodes
	}
};

export const saveBotFlow = (nodes) => {
	return {
		type: SET_BOT_FLOW,
		payload: nodes
	}
};

export const resetBotflow = () => {
	return {
		type: RESET_BOTFLOW
		
	}
};


export const setBotAccounts = (accounts) => {
	let data = {
		type: SET_BOT_ACCOUNTS,
		payload: accounts
	}
	return data
};

export const getBotAccounts = () => {
	return {
		type: GET_BOT_ACCOUNTS
	}
};

export const saveBotAccounts=(botAccounts)=>{
	return{
		type:SAVE_BOT_ACCOUNTS,
		payload:botAccounts
	}
}

