import "antd/dist/antd.css";
import "./assets/css/light-theme.css";
import "./App.css";
import Routes from "./Routes";

function App() {
    return (
        <div>
            <Routes />
        </div>
    );
}

export default App;
