import {
  AUTHENTICATED,
  AUTH_STATUS,
  GET_AUTH_STATUS
} from '../constants/Auth';

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token
  }
};

export const authStatus = (status) => {
  return {
    type: AUTH_STATUS,
    payload: status
  }
};

export const getAuthStatus = () => {
  return {
    type: GET_AUTH_STATUS
  }
}