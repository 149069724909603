import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { notification } from "antd";
import {
  GET_BOT_FLOW,
  SET_NODE,
  SHOW_LOADING,
  SET_BOT_FLOW,
  GET_BOT_ACCOUNTS
} from "../constants/BotFlow";

import {
  setNode,
  getBotFlow,
  showLoading,
  setBotAccounts,
  saveBotAccounts
} from "../actions/BotFlow";
import ApiService from "../../Services/ApiService";
import { toastNotification } from "../../utils/toastNotification";


function* fetchBotFlow() {
  yield takeEvery(GET_BOT_FLOW, function* () {
    try {
      const res = yield ApiService.get("/hulk/api/v1/getBotFlow");
      yield put(setNode(res));
      
    } catch (err) {
    //   yield put(authStatus(false));
    }
  });
  yield takeEvery(SET_BOT_FLOW, function* (action) {
    try {
      const res = yield ApiService.post("/hulk/api/v1/updateBot", {}, action.payload);
      yield put(setNode(action.payload));
      notification.success({
        message: "Flow Updated successfully"
      });
    } catch (err) {
    //   yield put(authStatus(false));
    }
  });

  yield takeEvery(GET_BOT_ACCOUNTS, function* () {
    try {
      const res2 = yield ApiService.get("/hulk/api/v1/bot-accounts");
      let stateVal = {};
      if (res2.data) {
        res2.data.map(acc => {
          stateVal[acc.channel] = {
            entityId: acc.entity_id,
            is_test: acc.is_test
          }; 
        })
      }
      yield put(setBotAccounts(stateVal));
      yield put(saveBotAccounts(res2.data))
    } catch (err) {
    //   yield put(authStatus(false));
			// toastNotification({errorMessage:"Internal Server Error"});

    }
  });
  
}

export default function* rootSaga() {
  // console.log("ROOT SAGA CALLED");
  yield all([fetchBotFlow()]);
}
