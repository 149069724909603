import fetch from "../Auth/Interceptor";

const apiService = {};

apiService.get = function (url, headers, params) {
  return fetch({
    url,
    method: "get",
    headers,
    params,
  });
};

apiService.post = function (url, headers, data) {
  return fetch({
    url,
    method: "post",
    headers,
    data: data,
  });
};

apiService.patch = function (url, headers, data, params) {
  return fetch({
    url,
    method: "patch",
    headers,
    data: data,
    params: params
  });
};

apiService.put = function (url, headers, data) {
  return fetch({
    url,
    method: "put",
    headers,
    data: data,
  });
};
apiService.delete = function (url, headers) {
  return fetch({
    url,
    method: "delete",
    headers,
  });
};

export default apiService;
