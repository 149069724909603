import {
	AUTH_TOKEN,
	AUTH_STATUS,
	AUTHENTICATED
} from "../constants/Auth";

const initState = {
	loading: false,
	token: localStorage.getItem(AUTH_TOKEN),
	authenticated: true,
};

const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				token: action.token,
			};
		case AUTH_STATUS:
			return {
				...state,
				loading: false,
				authenticated: action.payload,
			};
		default:
			return state;
	}
};

export default auth;
