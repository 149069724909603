import axios from "axios";
import history from "../history";
import { notification } from "antd";
import { env } from "../Configs/EnvironmentConfig";
const service = axios.create({
	baseURL: env.API_ENDPOINT_URL,
	timeout: 60000,
	withCredentials: true,
});
const ENTRY_ROUTE = "/login";
const relocateToLoginPage = (currentUrl) => {
	// let redirectUrlArr = currentUrl.split(env.FRONTEND_URL);
	// let redirectUrl = ENTRY_ROUTE;
	// if (redirectUrlArr.length > 1) {
	//   redirectUrl = `${ENTRY_ROUTE}?rd=${redirectUrl}`;
	// }
	// history.push(redirectUrl);
	history.push(ENTRY_ROUTE);
	window.location.reload();
};

const refeshTokenOnExpiry = () => {
	return new Promise((resolve, reject) => {
		let refreshToken = localStorage.getItem("refresh_token");
		if (refreshToken == null) {
			resolve({ status: "failed", data: null });
			return;
		}
		service({
			url: "/hulk/token",
			method: "POST",
			params: {
				refresh_token: refreshToken,
				na: "na",
			},
		})
			.then((response) => {
				if (response.status != "success") {
					resolve({ status: "failed", data: null });
					relocateToLoginPage();
					return;
				}
				return resolve({ status: "success", data: response.data });
			})
			.catch((err) => {
				console.error(err);
			});
	});
};

const isPublicUrl = () => {
	let currentUrl = window.location.href;
	let publicUrls = env.PUBLIC_URLS;
	console.log(currentUrl, publicUrls);
	let isPublic = publicUrls.some(url => currentUrl.indexOf(url) > -1);
	return isPublic;
}

// API Request interceptor
service.interceptors.request.use(
	(config) => {
		if (typeof config.headers["x-curr-url"] == "undefined") {
			config.headers["x-curr-url"] = window.location.href;
		}

		// let accessToken = localStorage.getItem("access_token");
		const params = new URLSearchParams(window.location.search)
		const accessToken=(params.has("token")||params.get("token"))&&params.get("token")
// console.log("hastoken",params.has("token"))
// console.log("token",params.get("token"))
		if (accessToken != null) {
			config.headers["Authorization"] = `Bearer ${accessToken}`;
		}

		return config;
	},
	(error) => {
		notification.error({
			message: "Error!",
		});
		Promise.reject(error);
	}
);

// API respone interceptor
service.interceptors.response.use(
	(response) => {
		if (typeof response.headers["x-refresh-required"] != "undefined") {
			refeshTokenOnExpiry()
				.then((data) => {
					if (data.status == "success") {
						localStorage.setItem("access_token", data.data.token);
					}
				})
		}
		return response.data;
	},
	(error) => {
		let notificationParam = {
			message: "",
		};

		if (error.response && error.response.status === 401) {
			let isPublic = isPublicUrl();

			if (!isPublic) {
				notificationParam.message = "Please login again";
				localStorage.removeItem("access_token");
				relocateToLoginPage(window.location.href);
				notification.warning(notificationParam);
			}
		}

		return Promise.reject(error);
	}
);

export default service;
