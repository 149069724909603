import {
	FETCH_SOCKET_INFO,
	SAVE_SOCKET_INFO,
	GET_MESSAGES,
	RESET_INBOX,
	GET_VISITORS,
	SAVE_MESSAGES,
	SAVE_VISITORS,
	GET_CANNED_RESPONSES,
	SAVE_CANNED_RESPONSES,
	MARK_READ,
	UPDATE_MARK_READ,
	FETCH_ONE_VISITOR,
	SAVE_BOT_AGENTS,
	GET_BOT_AGENTS,
	GET_AGENT_AVAILABILITY,
	SAVE_AGENT_AVAILABILITY,
	UPDATE_AGENT_AVAILABILITY,
	EVENT_AGENT_MESSAGE,
	UPDATE_VISITORS,
	SAVE_PARTIAL_VISITORS,
	CLOSE_CASE,
	RESET_VISITORS,
	SET_CURRENT_TAB,
	SAVE_USER_INBOX_TEAMS,
	GET_USER_INBOX_TEAMS,
	UPDATE_FILTER,
	AGENT_REASSIGN,
	GET_TAGS_INBOX,
	SAVE_TAGS_INBOX,
	GET_UNREAD,
	SAVE_UNREAD,
	SAVE_CURRENT_USER,
	RESET_INBOX_STATE,
	CREATE_DUMMY_MESSAGE,
	LIVE_VISITOR_TRANSFER,
	GET_LIVE_ONLINE_VISITOR_COUNT,
	SAVE_LIVE_ONLINE_VISITOR_COUNT,
	
} from '../constants/inbox';

export const getSocketInfo = (botStrId) => {
	return {
		type: FETCH_SOCKET_INFO,
		payload: { botStrId }
	}
};

export const saveSocketInfo = (botStrId, data) => {
	return {
		type: SAVE_SOCKET_INFO,
		payload: { botStrId, data }
	}
};

export const getVisitors = (where) => {
	return {
		type: GET_VISITORS,
		payload: where
	}
};

export const saveVisitors = (visitors) => {
	return {
		type: SAVE_VISITORS,
		payload: visitors
	}
};

export const getMessages = (visitorId) => {
	return {
		type: GET_MESSAGES,
		payload: { visitorId }
	}
};

export const saveMessages = (visitorId, messages) => {
	return {
		type: SAVE_MESSAGES,
		payload: { visitorId, messages }
	}
};

export const getCannedResponses = () => {
	return {
		type: GET_CANNED_RESPONSES
	}
};

export const saveCannedResponses = (cannedResponses) => {
	return {
		type: SAVE_CANNED_RESPONSES,
		payload: cannedResponses
	}
};

export const markRead = (visitorId) => {
	return {
		type: MARK_READ,
		payload: { visitorId }
	}
}

export const updateMarkRead = (visitorId) => {
	return {
		type: UPDATE_MARK_READ,
		payload: { visitorId }
	}
}

export const fetchOneVisitor = (visitorId) => {
	return {
		type: FETCH_ONE_VISITOR,
		payload: { visitorId }
	}
}

export const getBotAgents = () => {
	return {
		type: GET_BOT_AGENTS
	}
}

export const saveBotAgents = (agents) => {
	return {
		type: SAVE_BOT_AGENTS,
		payload: agents
	}
}

export const getAgentAvailability = () => {
	return {
		type: GET_AGENT_AVAILABILITY
	}
}

export const saveAgentAvailability = (status) => {
	return {
		type: SAVE_AGENT_AVAILABILITY,
		payload: status
	}
}

export const updateAgentAvailability = (status) => {
	return {
		type: UPDATE_AGENT_AVAILABILITY,
		payload: status
	}
}

export const sendAgentMessage = (messageBody) => {
	return {
		type: EVENT_AGENT_MESSAGE,
		payload: messageBody
	}
}
export const resetInbox=()=>{
	return{
		type:RESET_INBOX,
	}
}

export const updateVisitor = (id, body) => {
	return {
		type: UPDATE_VISITORS,
		payload: { id, body }
	}
}

export const savePartialVisitor = (id, body) => {
	return {
		type: SAVE_PARTIAL_VISITORS,
		payload: { id, body }
	}
}

export const closeCase = (body) => {
	return {
		type: CLOSE_CASE,
		payload: { body }
	}
}

export const resetVisitors = () => {
	return {
		type: RESET_VISITORS
	}
}

export const setCurrentTab = (currentTab) => {
	return {
		type: SET_CURRENT_TAB,
		payload: currentTab
	}
}

export const getUserInboxTeams = () => {
	return {
		type: GET_USER_INBOX_TEAMS
	}
}

export const saveUserInboxTeams = (teams) => {
	return {
		type: SAVE_USER_INBOX_TEAMS,
		payload: teams
	}
}

export const updateFilter = (filter) => {
	return {
		type: UPDATE_FILTER,
		payload: filter
	}
}

export const agentReassign = (body) => {
	return {
		type: AGENT_REASSIGN,
		payload: { body }
	}
}


export const getTagsInbox = () => {
	return {
		type: GET_TAGS_INBOX
	}
}

export const saveTagsInbox = (tags) => {
	return {
		type: SAVE_TAGS_INBOX,
		payload: tags
	}
}

export const getUnread = (visitorIds) => {
	return {
		type: GET_UNREAD,
		payload: visitorIds
	}
}

export const saveUnread = (data) => {
	return {
		type: SAVE_UNREAD,
		payload: data
	}
}

export const saveCurrentUser = (data) => {
	return {
		type: SAVE_CURRENT_USER,
		payload: data
	}
}

export const resetInboxState = () => {
	return {
		type: RESET_INBOX_STATE
	}
}

export const createDummyMessage = (data, visitorId) => {
	return {
		type: CREATE_DUMMY_MESSAGE,
		payload: {data, visitorId}
	}
}

export const liveVisitorTransfer = (body) => {
	return {
		type: LIVE_VISITOR_TRANSFER,
		payload: {body}
	}
}

export const getLiveOnlineVisitorCount = () => {
	return {
		type: GET_LIVE_ONLINE_VISITOR_COUNT
	}
}

export const saveLiveOnlineVisitorCount = (data) => {
	return {
		type: SAVE_LIVE_ONLINE_VISITOR_COUNT,
		payload: data
	}
}
