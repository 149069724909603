import { all, takeEvery, put } from "redux-saga/effects";
import {
  AUTH_TOKEN,
  GET_AUTH_STATUS,
} from "../constants/Auth";
import {
  authStatus,
  authenticated
} from "../actions/Auth";
import ApiService from "../../Services/ApiService";

export function* userStatus() {
  yield takeEvery(GET_AUTH_STATUS, function* () {
		try {
			const res = yield ApiService.get("/hulk/auth_check");
			if (res.status == "success") {
        yield put(authenticated(localStorage.getItem(AUTH_TOKEN)));
        yield put(authStatus(true));
      }
		} catch (error) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      yield put(authenticated(null));
      yield put(authStatus(false));
		}
	});
}

export default function* rootSaga() {
  yield all([userStatus()]);
}
