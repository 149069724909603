import { all, takeEvery, put } from "redux-saga/effects";
import ApiService from "../../Services/ApiService";
import { toastNotification } from "../../utils/toastNotification";
import { GET_CRM_SCHEMA, UPDATE_CRM_SCHEMA } from "../constants/common";
import { saveCrmSchema, getCrmSchema } from "../actions/common";

export function* getCrmSchemaSaga() {
    yield takeEvery(GET_CRM_SCHEMA, function* () {
        try {
            const res = yield ApiService.get("/ironman/crm-schemas", {}, {
                filter: {
                    fields: {
                        schema: true
                    }
                }
            })
            if (res && res.status == "success") {
                yield put(saveCrmSchema(res.data))
            }
        } catch (error) {
            console.log(error)
            toastNotification({ res: null, errorMessage: "Unable to load variables" });
        }
    });
}

export function* updateCrmSchemaSaga() {
    yield takeEvery(UPDATE_CRM_SCHEMA, function* (action) {
        try {
            const res = yield ApiService.put("/ironman/crm-schemas", {}, action.payload)
            if (res && res.status == "success") {
                toastNotification({ res: null, successMessage: "Crm Schema Updated successfully" });
                yield put(getCrmSchema())
            }
        } catch (error) {
            console.log(error)
            toastNotification({ res: null, errorMessage: "Unable to update crm schema" });
        }
    });
}

export default function* rootSaga() {
    yield all([
        getCrmSchemaSaga(),
        updateCrmSchemaSaga()
    ]);
}
