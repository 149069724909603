export const FETCH_SOCKET_INFO = 'FETCH_SOCKET_INFO';
export const SAVE_SOCKET_INFO = 'SAVE_SOCKET_INFO';
export const EVENT_NEW_MESSAGE = 'EVENT_NEW_MESSAGE';
export const EVENT_AGENT_MESSAGE = 'EVENT_AGENT_MESSAGE';
export const EVENT_AGENT_ASSIGNED = 'EVENT_AGENT_ASSIGNED';
export const EVENT_STATUS_CHANGED = 'EVENT_STATUS_CHANGED';
export const EVENT_CONNECTION = 'EVENT_CONNECTION';
export const GET_VISITORS = 'GET_VISITORS';
export const SAVE_VISITORS = 'SAVE_VISITORS';
export const RESET_VISITORS = 'RESET_VISITORS';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SAVE_PARTIAL_VISITORS = 'SAVE_PARTIAL_VISITORS';
export const CLOSE_CASE = 'CLOSE_CASE';
export const UPDATE_VISITORS = 'UPDATE_VISITORS';
export const GET_MESSAGES = 'GET_MESSAGES';
export const SAVE_MESSAGES = 'SAVE_MESSAGES';
export const GET_CANNED_RESPONSES = 'GET_CANNED_RESPONSES';
export const SAVE_CANNED_RESPONSES = 'SAVE_CANNED_RESPONSES';
export const MARK_READ = 'MARK_READ';
export const UPDATE_MARK_READ = 'UPDATE_MARK_READ';
export const FETCH_ONE_VISITOR = 'FETCH_ONE_VISITOR';
export const GET_BOT_AGENTS = 'GET_BOT_AGENTS';
export const SAVE_BOT_AGENTS = 'SAVE_BOT_AGENTS';
export const GET_AGENT_AVAILABILITY = 'GET_AGENT_AVAILABILITY';
export const UPDATE_AGENT_AVAILABILITY = 'UPDATE_AGENT_AVAILABILITY';
export const SAVE_AGENT_AVAILABILITY = 'SAVE_AGENT_AVAILABILITY';
export const GET_USER_INBOX_TEAMS = 'GET_USER_INBOX_TEAMS';
export const SAVE_USER_INBOX_TEAMS = 'SAVE_USER_INBOX_TEAMS';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const AGENT_REASSIGN = 'AGENT_REASSIGN';
export const GET_TAGS_INBOX = 'GET_TAGS_INBOX';
export const SAVE_TAGS_INBOX = 'SAVE_TAGS_INBOX';
export const GET_UNREAD = 'GET_UNREAD';
export const SAVE_UNREAD = 'SAVE_UNREAD';
export const SAVE_CURRENT_USER = "SAVE_CURRENT_USER";
export const RESET_INBOX_STATE = "RESET_INBOX_STATE";
export const CREATE_DUMMY_MESSAGE = "CREATE_DUMMY_MESSAGE";
export const LIVE_VISITOR_TRANSFER = "LIVE_VISITOR_TRANSFER";
export const GET_LIVE_ONLINE_VISITOR_COUNT = "GET_LIVE_ONLINE_VISITOR_COUNT";
export const SAVE_LIVE_ONLINE_VISITOR_COUNT = "SAVE_LIVE_ONLINE_VISITOR_COUNT";
export const RESET_INBOX="RESET_INBOX";
