import {
	all,
	takeEvery,
	put
} from "redux-saga/effects";
import {
	saveUsers,
	saveOrg,
	saveCannedMessages,
	saveCannedMessagesCurrent,
	saveBussinessHourList,
	saveCannedGroups,
	saveBhourTeam,
	saveSetting,
	saveBulkUsers,
	saveUserTeam,
	saveCsat,
	saveAccess,
	saveTags,
	getTeamList,
	saveUsersAndTeamMetadata,
} from "../actions/UsersAndTeam";
import { authStatus, authenticated } from "../../Redux/actions/Auth";
import {
	GET_TEAM_LIST,
	CREATE_NEW_TEAM,
	CANNED_MESSAGE_LIST_SUCCESS,
	CREATE_BUSINESS_HOUR,
	CREATE_CANNED_MESSAGE,
	CANNED_MESSAGE_CURRENT,
	GET_BUSINESS_HOUR_LIST,
	UPDATE_BUSINESS_HOUR,
	CANNED_GROUP_LIST_SUCCESS,
	LOAD_CANNED_GROUP,
	UPDATE_CANNED_GROUP,
	CREATE_CANNED_GROUP,
	UPDATE_CANNED_MESSAGE,
	UPDATE_NEW_TEAM,
	GET_ORG,
	SAVE_ORG,
	GET_SETTING,
	UPDATE_ORG,
	BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM,
	CREATE_BULK_USER,
	GET_BULK_USER,
	GET_USER_TEAM,
	UPDATE_USER,
	LOGOUT_USER,
	UPDATE_SETTING,
	GET_CSAT_CONFIG,
	CREATE_CSAT_CONFIG,
	GET_ACCESS,
	GET_TAGS,
	ADD_TAGS,
	ADD_SUB_TAGS,
	UPDATE_TAGS,
	UPDATE_SUB_TAGS,
	GET_USER_TEAM_METADATA,
} from "../constants/UsersAndTeam";
import ApiService from "../../Services/ApiService";
import { updateApiStatus } from "../actions/common";
import { toastNotification } from '../../utils/toastNotification'
import { saveCurrentUser } from "../actions/inbox";

function* getSetting() {
	yield takeEvery(GET_SETTING, function* () {
		try {
			const res = yield ApiService.get("/hulk/api/v1/settings");
			if (res) {
				yield put(saveSetting(res));
				yield put(saveCurrentUser(res));
			}
		} catch (err) {

			yield put(updateApiStatus({ status: err.status, message: err.message }));
			yield put(authStatus(false));
			yield put(console.log(err));
		}
	});
}
function* updateSetting() {
	yield takeEvery(UPDATE_SETTING, function* (action) {
		try {
			const res = yield ApiService.put(
				`/hulk/api/v1/profile`,
				{},
				action.payload
			);
			toastNotification({ res: res })
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))

				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))

				}
			}
			yield put({ type: GET_SETTING });
		} catch (err) {

			//toast Notificaton
			toastNotification({
				errorMessage: "Internal Server Error"
			})

			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}

function* getAccess() {
	yield takeEvery(GET_ACCESS, function* (action) {
		try {
			const res = yield ApiService.get("/hulk/api/v1/access", { "x-curr-url": action.payload });
			if (res) {
				yield put(saveAccess(res));
			}
		} catch (err) {
			console.log(err)
			yield put(updateApiStatus({ status: err.status, message: err.message }));
		}
	});
}

function* getUsers() {
	yield takeEvery(GET_TEAM_LIST, function* () {
		try {
			const res = yield ApiService.get("/hulk/api/v1/teams");
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
			yield put(saveUsers(res.data));
		} catch (err) {
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}

function* getBulkUsers() {
	yield takeEvery(GET_BULK_USER, function* () {
		try {
			const res = yield ApiService.get("/hulk/api/v1/users");
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
			yield put(saveBulkUsers(res.data));
		} catch (err) {
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* getUsersTeam() {
	yield takeEvery(GET_USER_TEAM, function* (action) {
		try {
			const res = yield ApiService.get(
				"/hulk/api/v1/users",
				{},
				{ team: action.payload }
			);
			yield put(saveUserTeam(res.data));
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}

function* getCannedMessages() {
	yield takeEvery(CANNED_MESSAGE_LIST_SUCCESS, function* () {
		try {
			const res = yield ApiService.get("/hulk/api/v1/canned-responses");
			yield put(saveCannedMessages(res.data));
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* getCannedMessagesCurrent() {
	yield takeEvery(CANNED_MESSAGE_CURRENT, function* (action) {
		try {
			const res = yield ApiService.get(
				"/hulk/api/v1/canned-responses",
				{},
				{ canned_group: action.payload }
			);
			yield put(saveCannedMessagesCurrent(res.data));
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* createUsers() {
	yield takeEvery(CREATE_NEW_TEAM, function* (action) {
		try {
			const res = yield ApiService.post(
				"/hulk/api/v1/teams",
				{},
				{ name: action.payload }
			);

			toastNotification({ res: res })

			if (res) {
				if (res.status !== "success") {
					yield put(getTeamList());
					yield put(updateApiStatus({ status: "error", message: res.message }))

				}
				else if (res.status === "success" && res.message) {
					yield put(getTeamList());
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
			yield put({ type: GET_TEAM_LIST });
		} catch (err) {
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* createUsersReal() {
	yield takeEvery(CREATE_BULK_USER, function* (action) {
		try {
			const res = yield ApiService.post(
				"/hulk/api/v1/platform-users-bulk",
				{},
				{
					...action.payload,
				}
			);

			toastNotification({ res: null, successMessage: "Invitation sent successfully" });

			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
			yield put({ type: GET_BULK_USER });
			yield put({type: GET_USER_TEAM_METADATA});
		} catch (err) {

			toastNotification({ errorMessage: "Internal Server Error" })

			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* updateUserReal() {
	yield takeEvery(UPDATE_USER, function* (action) {
		try {
			const res = yield ApiService.put(
				`hulk/api/v1/users`,
				{},
				action.payload.body
			);

			toastNotification({ res: null, successMessage: "Updated user successfully" })

			if (action.payload.id) {
				yield put({ type: GET_USER_TEAM, payload: action.payload.id });
			} else {
				yield put({ type: GET_BULK_USER });
			}
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}

			yield put({type: GET_USER_TEAM_METADATA});
		} catch (err) {
			toastNotification({ errorMessage: "Internal Server Error" })

			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}

function* updateTeam() {
	yield takeEvery(UPDATE_NEW_TEAM, function* (action) {
		try {
			const res = yield ApiService.put(
				`hulk/api/v1/teams/${action.payload.id}`,
				{},
				action.payload.body
			);

			toastNotification({
				res
			})

			yield put({ type: GET_TEAM_LIST });
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {

			toastNotification({ errorMessage: "Internal Server Error" })
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* createCannedMessage() {
	yield takeEvery(CREATE_CANNED_MESSAGE, function* (action) {
		try {
			const res = yield ApiService.post(
				"hulk/api/v1/canned-responses",
				{},
				action.payload
			);

			toastNotification({ res })

			yield put({
				type: CANNED_MESSAGE_CURRENT,
				payload: res.data.canned_group.id,
			});
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {
			toastNotification({ errorMessage: "Internal Server Error" })

			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* updateCannedMessage() {
	yield takeEvery(UPDATE_CANNED_MESSAGE, function* (action) {
		try {
			const res = yield ApiService.put(
				`hulk/api/v1/canned-responses/${action.payload.id}`,
				{},
				action.payload.body
			);
			// yield put({ type: CANNED_MESSAGE_LIST_SUCCESS });
			toastNotification({ res })

			yield put({
				type: CANNED_MESSAGE_CURRENT,
				payload: res.data.canned_group.id,
			});
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {
			toastNotification({ errorMessage: "Internal Server Error" })

			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* getBussinessHours() {
	yield takeEvery(GET_BUSINESS_HOUR_LIST, function* () {
		try {
			const res = yield ApiService.get("/hulk/api/v1/business-hours");
			yield put(saveBussinessHourList(res.data));
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* getBussinessHoursTeam() {
	yield takeEvery(BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM, function* () {
		try {
			const res = yield ApiService.get(
				"/hulk/api/v1/teams-unassigned-business-hours"
			);
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
			yield put(saveBhourTeam(res.data));
		} catch (err) {
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* createBussinessHour() {
	yield takeEvery(CREATE_BUSINESS_HOUR, function* (action) {
		try {
			const res = yield ApiService.post(
				"/hulk/api/v1/business-hours",
				{},
				action.payload
			);
			toastNotification({ res })
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
			yield put({ type: GET_BUSINESS_HOUR_LIST });
		} catch (err) {
			toastNotification({ errorMessage: "Internal Server Error" })
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* updateBussinessHour() {
	yield takeEvery(UPDATE_BUSINESS_HOUR, function* (action) {
		try {
			const res = yield ApiService.put(
				`/hulk/api/v1/business-hours/${action.payload.id}`,
				{},
				action.payload.body
			);
			toastNotification({ res })
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
			yield put({ type: GET_BUSINESS_HOUR_LIST });
		} catch (err) {
			toastNotification({ errorMessage: "Internal Server Error" })
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* createCannedGroup() {
	yield takeEvery(CREATE_CANNED_GROUP, function* (action) {
		try {
			const res = yield ApiService.post(
				"/hulk/api/v1/canned-groups",
				{},
				action.payload
			);
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
			yield put({ type: CANNED_GROUP_LIST_SUCCESS });
		} catch (err) {
			toastNotification({ errorMessage: "Internal Server Error" })
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* updateCannedGroup() {
	yield takeEvery(UPDATE_CANNED_GROUP, function* (action) {
		try {
			const res = yield ApiService.put(
				`hulk/api/v1/canned-groups/${action.payload.id}`,
				{},
				action.payload.body
			);
			toastNotification({ res })
			yield put({ type: CANNED_GROUP_LIST_SUCCESS });
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {
			toastNotification({ errorMessage: "Internal Server Error" })
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* getCannedGroup() {
	yield takeEvery(CANNED_GROUP_LIST_SUCCESS, function* () {
		try {
			const res = yield ApiService.get("/hulk/api/v1/canned-groups");
			yield put(saveCannedGroups(res.data));
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {

			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* getOrg() {
	yield takeEvery(GET_ORG, function* (action) {
		try {
			const res = yield ApiService.get(`/hulk/api/v1/organizations`, {"x-org-str": action.payload});
			yield put(saveOrg(res.data));
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* updateOrg() {
	yield takeEvery(UPDATE_ORG, function* (action) {
		try {
			const res = yield ApiService.put(
				`hulk/api/v1/organizations`,
				{"x-org-str": action.payload.orgStrId},
				action.payload.body
			);
			toastNotification({ res: null, successMessage: "Organization details updated successfully" })
			yield put({ type: GET_ORG, payload: action.payload.orgStrId });
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {
			toastNotification({ errorMessage: "Internal Server Error" });
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* getCsat() {
	yield takeEvery(GET_CSAT_CONFIG, function* (action) {
		try {
			const res = yield ApiService.get(`/hulk/api/v1/csat-configurations`);
			yield put(saveCsat(res.data));
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* createCsat() {
	yield takeEvery(CREATE_CSAT_CONFIG, function* (action) {
		try {
			const res = yield ApiService.post(
				`/hulk/api/v1/csat-configurations-bulk`,
				{},
				action.payload
			);
			toastNotification({ res })
			yield put({ type: GET_CSAT_CONFIG });
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {
			toastNotification({ errorMessage: "Internal Server Error" });
			yield put(console.log(err));
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}
function* logOutUser() {
	yield takeEvery(LOGOUT_USER, function* (action) {
		try {
			const res = yield ApiService.get(`/hulk/logout`);
			// toastNotification({ res })
			localStorage.removeItem("access_token");
			localStorage.removeItem("refresh_token");
			yield put(authenticated(null));
			yield put(authStatus(false));
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
				}
			}
		} catch (err) {
			localStorage.removeItem("access_token");
			localStorage.removeItem("refresh_token");
			// toastNotification({ errorMessage: "Internal Server Error" });
			yield put(console.log(err));
			yield put(authenticated(null));
			yield put(authStatus(false));
			yield put(updateApiStatus({ status: "error", message: err.message }))
			yield put(authStatus(false));
		}
	});
}

function* getTags() {
	yield takeEvery(GET_TAGS, function* () {
		try {
			const res = yield ApiService.get(`/hulk/api/v1/tags`);
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success") {
					yield put(updateApiStatus({ status: "success", message: res.message }))
					yield put(saveTags(res.data))
				}
			}
		} catch (err) {
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}

function* addTags() {
	yield takeEvery(ADD_TAGS, function* (action) {
		try {
			const res = yield ApiService.post(`/hulk/api/v1/tags`, {}, action.payload);
			yield put({ type: GET_TAGS });
		} catch (err) {
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}

function* updateTags() {
	yield takeEvery(UPDATE_TAGS, function* (action) {
		try {
			const res = yield ApiService.put(`/hulk/api/v1/tags/${action.payload.id}`, {}, action.payload.body);
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
					yield put({ type: GET_TAGS })
				}
			}
		} catch (err) {
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}

function* addSubTags() {
	yield takeEvery(ADD_SUB_TAGS, function* (action) {
		try {
			const res = yield ApiService.post(`/hulk/api/v1/sub-tags`, {}, action.payload);
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
					// yield put({type: GET_TAGS})
				}
			}
		} catch (err) {
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}

function* updateSubTags() {
	yield takeEvery(UPDATE_SUB_TAGS, function* (action) {
		try {
			const res = yield ApiService.put(`/hulk/api/v1/sub-tags/${action.payload.id}`, {}, action.payload.body);
			if (res) {
				if (res.status !== "success") {
					yield put(updateApiStatus({ status: "error", message: res.message }))
				}
				else if (res.status === "success" && res.message) {
					yield put(updateApiStatus({ status: "success", message: res.message }))
					// yield put({type: GET_TAGS})
				}
			}
		} catch (err) {
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}

function* getUsersAndTeamMetadataSaga() {
	yield takeEvery(GET_USER_TEAM_METADATA, function* (action) {
		try {
			const res = yield ApiService.get("hulk/api/v1/user_metadata");
			if (res && res.status == "success") {
				yield put(saveUsersAndTeamMetadata(res.data));
			}
		} catch (err) {
			yield put(updateApiStatus({ status: "error", message: err.message }))
		}
	});
}

export default function* rootSaga() {
	yield all([
		getUsers(),
		createUsers(),
		getCannedMessages(),
		createCannedMessage(),
		getCannedMessagesCurrent(),
		getBussinessHours(),
		createBussinessHour(),
		updateBussinessHour(),
		createCannedGroup(),
		getCannedGroup(),
		updateCannedGroup(),
		updateCannedMessage(),
		updateTeam(),
		getOrg(),
		updateOrg(),
		getBussinessHoursTeam(),
		getSetting(),
		createUsersReal(),
		getBulkUsers(),
		getUsersTeam(),
		updateUserReal(),
		logOutUser(),
		updateSetting(),
		createCsat(),
		getCsat(),
		getAccess(),
		getTags(),
		addTags(),
		updateTags(),
		addSubTags(),
		updateSubTags(),
		getUsersAndTeamMetadataSaga()
	]);
}
