import { all } from "redux-saga/effects";

import inbox from "./inbox";
import UsersAndTeam from "./UsersAndTeam";
import botFlow from "./BotFlow";
import Auth from "./Auth"
import common from "./common"
export default function* rootSaga(getState) {
  yield all([
    inbox(),
    UsersAndTeam(),
    botFlow(),
    Auth(),
    common()

  ]);
}
