import {
    FETCH_SOCKET_INFO,
    SAVE_SOCKET_INFO,
    EVENT_NEW_MESSAGE,
    GET_VISITORS,RESET_INBOX,
    GET_MESSAGES,
    SAVE_VISITORS,
    SAVE_MESSAGES,
    GET_CANNED_RESPONSES,
    SAVE_CANNED_RESPONSES,
    MARK_READ,
    UPDATE_MARK_READ,
    FETCH_ONE_VISITOR,
    GET_BOT_AGENTS,
    SAVE_BOT_AGENTS,
    EVENT_CONNECTION,
    GET_AGENT_AVAILABILITY,
    SAVE_AGENT_AVAILABILITY,
    UPDATE_AGENT_AVAILABILITY,
    EVENT_AGENT_MESSAGE,
    UPDATE_VISITORS,
    CLOSE_CASE,
    RESET_VISITORS,
    SET_CURRENT_TAB,
    GET_USER_INBOX_TEAMS,
    SAVE_USER_INBOX_TEAMS,
    UPDATE_FILTER,
    AGENT_REASSIGN,
    GET_TAGS_INBOX,
    SAVE_TAGS_INBOX,
    GET_UNREAD,
    SAVE_UNREAD,
    SAVE_CURRENT_USER,
    RESET_INBOX_STATE,
    CREATE_DUMMY_MESSAGE,
    LIVE_VISITOR_TRANSFER,
    GET_LIVE_ONLINE_VISITOR_COUNT,
    SAVE_LIVE_ONLINE_VISITOR_COUNT,
   
} from "../constants/inbox";

const initState = {
    socketData: {},
    visitors: [],
    cannedResponses: [],
    loading: 0,
    agents: [],
    connected: null,
    availabilityStatus: 0,
    visitorResetCounter: 0,
    currentTab: -1,
    userTeams: [],
    filter: { teams: [], status: [], channels: [], counter: 0 },
    inboxTags: [],
    currentUser: null,
    liveVisitorCount: 0
};

const inbox = (state = initState, action) => {
    switch (action.type) {
        case RESET_INBOX_STATE:
            return {
                ...state,
                visitors: [],
                currentTab: -1,
                filter: { teams: [], status: [], channels: [], counter: 0 }
            }
        case SAVE_SOCKET_INFO:
            return {
                ...state,
                socketData: { ...state.socketData, [action.payload.botStrId]: action.payload.data },
                loading: state.loading - 1
            };
            case RESET_INBOX:
                return{
                    socketData: {},
                    visitors: [],
                    cannedResponses: [],
                    loading: 0,
                    agents: [],
                    connected: null,
                    availabilityStatus: 0,
                    visitorResetCounter: 0,
                    currentTab: -1,
                    userTeams: [],
                    filter: { teams: [], status: [], channels: [], counter: 0 },
                    inboxTags: [],
                    currentUser: null,
                    liveVisitorCount: 0
                }
        case FETCH_SOCKET_INFO:
            return {
                ...state,
                loading: state.loading + 1
            };
        case EVENT_NEW_MESSAGE:
            let updatedVisitorsAfterNewMessage = state.visitors;
            let availabilityStatus = "Offline";
            if (action.payload.forTab == 4) {
                if (state.currentTab == 4) {
                    updatedVisitorsAfterNewMessage = updateVisitorOnPresenceMessage(state.visitors, action.payload.visitor);
                }
                state.liveVisitorCount = action.payload.visitor.totalLive;
            } else {
                if (action.payload.status !== undefined) {
                    availabilityStatus = action.payload.status == "Available" ? 1 : 0
                } else if (action.payload.message == null) {
                    updatedVisitorsAfterNewMessage = processNewMessageFromVariousSubscribers(state, action);
                } else if (action.payload.forTab == 1 && state.currentTab == 1) {
                    let isValid = validateFilterOnIncomingMessage(action, state);
                    isValid = isValid && validateUserTeamOnIncomingMessage(action, state);
                    if (isValid) {
                        updatedVisitorsAfterNewMessage = processNewMessageFromVariousSubscribers(state, action);
                    }
                } else if (action.payload.forTab == 2 && state.currentTab > 1) {
                    if (state.currentTab == 2) {
                        let isValid = true;
    
                        if (typeof action.payload.visitor.visitor_agent == "undefined" ||
                            typeof action.payload.visitor.visitor_agent.team_id == "undefined" ||
                            action.payload.visitor.visitor_agent.team_id == null ||
                            action.payload.visitor.visitor_agent.team_id <= 0) {
                            isValid = false;
                        }
                        isValid = isValid && validateFilterOnIncomingMessage(action, state);
                        isValid = isValid && validateUserTeamOnIncomingMessage(action, state);
    
                        if (isValid) {
                            updatedVisitorsAfterNewMessage = processNewMessageFromVariousSubscribers(state, action);
                        }
                    } else if (state.currentTab == 3) {
                        let isValid = true;
                        isValid = isValid && validateFilterOnIncomingMessage(action, state);
                        isValid = isValid && validateUserTeamOnIncomingMessage(action, state);
    
                        if (isValid) {
                            updatedVisitorsAfterNewMessage = processNewMessageFromVariousSubscribers(state, action);
                        }
                    }
                }
            }
            return {
                ...state,
                visitors: [...updatedVisitorsAfterNewMessage],
                availabilityStatus
            };
        case SAVE_VISITORS:
            let updatedVisitors = updateVisitorAfterGetVisitor(state.visitors, action.payload);
            return {
                ...state,
                visitors: [...updatedVisitors],
                loading: state.loading - 1
            }
        case GET_VISITORS:
            return {
                ...state,
                loading: state.loading + 1
            }
        case SAVE_MESSAGES:
            let updatedVisitorsAfterGetMessages = updateMessagesAfterGetMessages(state.visitors, action.payload.visitorId, action.payload.messages);
            return {
                ...state,
                visitors: [...updatedVisitorsAfterGetMessages],
                loading: state.loading - 1
            }
        case RESET_VISITORS:
            return {
                ...state,
                visitors: [],
                visitorResetCounter: state.visitorResetCounter + 1
            }
        case GET_MESSAGES:
            return {
                ...state,
                loading: state.loading + 1
            }
        case SAVE_CANNED_RESPONSES:
            return {
                ...state,
                cannedResponses: [...action.payload],
                loading: state.loading - 1
            }
        case SET_CURRENT_TAB:
            return {
                ...state,
                currentTab: action.payload
            }
        case GET_CANNED_RESPONSES:
            return {
                ...state,
                loading: state.loading + 1
            }
        case MARK_READ:
            return {
                ...state
            }
        case UPDATE_MARK_READ:
            let stateVisitorsAfterMarkRead = updateMarkRead(state.visitors, action.payload.visitorId);
            return {
                ...state,
                visitors: [...stateVisitorsAfterMarkRead]
            }
        case FETCH_ONE_VISITOR:
            return {
                ...state,
                loading: state.loading + 1
            }
        case GET_BOT_AGENTS:
            return {
                ...state,
                loading: state.loading + 1
            }
        case SAVE_BOT_AGENTS:
            return {
                ...state,
                agents: [...action.payload],
                loading: state.loading - 1
            }
        case EVENT_CONNECTION:
            return {
                ...state,
                connected: action.payload
            }
        case GET_AGENT_AVAILABILITY:
            return {
                ...state,
                loading: true,
                loading: state.loading + 1
            }
        case SAVE_AGENT_AVAILABILITY:
            return {
                ...state,
                availabilityStatus: action.payload,
                loading: state.loading - 1
            }
        case UPDATE_AGENT_AVAILABILITY:
            return {
                ...state,
                loading: state.loading + 1
            }
        case UPDATE_VISITORS:
            return {
                ...state,
                loading: state.loading + 1
            }
        case EVENT_AGENT_MESSAGE:
            return {
                ...state
            }
        case CLOSE_CASE:
            let visitorsAfterCaseClose = handleAgentReasignOrCaseClose(state, action.payload.body.visitor_id);
            return {
                ...state,
                visitors: [...visitorsAfterCaseClose]
            }
        case GET_USER_INBOX_TEAMS:
            return {
                ...state,
                loading: state.loading + 1
            }
        case SAVE_USER_INBOX_TEAMS:
            return {
                ...state,
                loading: state.loading - 1,
                userTeams: [...action.payload]
            }
        case UPDATE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case AGENT_REASSIGN:
            let visitorsAfterReassign = handleAgentReasignOrCaseClose(state, action.payload.body.visitor_id);
            return {
                ...state,
                visitors: [...visitorsAfterReassign]
            }
        case LIVE_VISITOR_TRANSFER:
            let visitorsAfterLiveVisitorTransfer = handleAgentReasignOrCaseClose(state, action.payload.body.presence_visitor_id);
            return {
                ...state,
                visitors: [...visitorsAfterLiveVisitorTransfer]
            }
        case GET_TAGS_INBOX:
            return {
                ...state,
                loading: state.loading + 1
            }
        case SAVE_TAGS_INBOX:
            return {
                ...state,
                loading: state.loading - 1,
                inboxTags: [...action.payload]
            }
        case GET_UNREAD:
            return {
                ...state,
                loading: state.loading + 1
            }
        case SAVE_UNREAD:
            //do something with action.payload
            return {
                ...state,
                loading: state.loading - 1
            }
        case SAVE_CURRENT_USER:
            return {
                ...state,
                currentUser: {...action.payload}
            }
        case CREATE_DUMMY_MESSAGE:
            let updatedVisitorsAfterDummyMessage = processDummyMessage(state, action.payload.data, action.payload.visitorId);
            return {
                ...state,
                visitors: [...updatedVisitorsAfterDummyMessage]
            }
        case GET_LIVE_ONLINE_VISITOR_COUNT:
            return {
                ...state
            }
        case SAVE_LIVE_ONLINE_VISITOR_COUNT:
            return {
                ...state,
                liveVisitorCount: action.payload.count
            }
        default:
            return state;
    }
};

const processDummyMessage = (state, message, visitorId) => {
    let visitorIndex = state.visitors.findIndex((visitor) => visitor.visitor_id == visitorId);
    state.visitors[visitorIndex].messages.push(message);
    new Audio("/audio/send.mp3").play();
    return state.visitors;
}

const handleAgentReasignOrCaseClose = (state, visitorId) => {
    if (state.currentTab == 1 || state.currentTab == 4) {
        let visitorIndex = state.visitors.findIndex(visitor => visitor.visitor_id == visitorId);

        if (visitorIndex > -1) {
            state.visitors.splice(visitorIndex, 1);
        }
    }
    return state.visitors;
}

const validateUserTeamOnIncomingMessage = (action, state) => {
    let isValid = true;

    if (typeof action.payload.visitor.visitor_agent != "undefined" && action.payload.visitor.visitor_agent != null && action.payload.visitor.visitor_agent.team_id != null) {
        if (state.userTeams.length == 0) {
            isValid = false;
        } else {
            isValid = state.userTeams[0].teams.some(team => team.team.id == action.payload.visitor.visitor_agent.team_id);
        }
    }

    return isValid;
}

const validateFilterOnIncomingMessage = (action, state) => {
    let isValid = true;
    if (typeof action.payload.visitor.visitor_agent != "undefined" && action.payload.visitor.visitor_agent != null) {
        isValid = isValid && (state.filter.teams.length === 0 || state.filter.teams.includes(action.visitor.visitor_agent.team_id));
    }

    if (typeof action.payload.visitor.visitor_source != "undefined" && action.payload.visitor.visitor_source != null) {
        isValid = isValid && (state.filter.channels.length === 0 || state.filter.channels.includes(action.payload.visitor.visitor_source));
    }

    if (typeof action.payload.visitor.current_ticket_status != "undefined" && action.payload.visitor.current_ticket_status != null) {

        if (state.filter.status.includes("Bot")) {
            if (typeof action.payload.visitor.visitor_agent == "undefined" || action.payload.visitor.visitor_agent == null) {
                isValid = isValid && true;
            }
        } else {
            isValid = isValid && (state.filter.status.length === 0 || state.filter.status.includes(action.payload.visitor.current_ticket_status));
        }
    }

    return isValid;
}

const processNewMessageFromVariousSubscribers = (state, action) => {
    let updatedVisitorsAfterNewMessage = null;
    if (action.payload.message != null) {
        updatedVisitorsAfterNewMessage = updateVisitorsAfterNewMessage(state.visitors, action.payload, state.currentUser, state.currentTab);
    } else {
        updatedVisitorsAfterNewMessage = updateVisitorsAfterTicketUpdate(state.visitors, action.payload);
    }

    return updatedVisitorsAfterNewMessage;
}

const updateMarkRead = (stateVisitors, visitorId) => {
    let index = stateVisitors.findIndex((visitor) => visitor.visitor_id == visitorId);

    if (index > -1) {
        let newMessages = stateVisitors[index].messages.map((message) => {
            message.message_seen = true;
            return message;
        });
        stateVisitors[index].messages = newMessages;
        stateVisitors[index].unread = 0;
    }

    return stateVisitors;
}

const updateMessagesAfterGetMessages = (stateVisitors, visitorId, messages) => {
    let index = stateVisitors.findIndex((visitor) => visitor.visitor_id == visitorId);

    if (index > -1) {
        stateVisitors[index].messages = messages;
    }

    return stateVisitors;
}

const updateVisitorAfterGetVisitor = (stateVisitors, newVisitors) => {

    for (let newVisitor of newVisitors) {
        let index = stateVisitors.findIndex((visitor) => visitor.visitor_id == newVisitor.visitor_id);

        if (index == -1) {
            newVisitor.messages = [];
            stateVisitors.push(newVisitor);
            index++;
        } else {
            newVisitor.messages = stateVisitors[index] || [];
            stateVisitors[index] = newVisitor;
        }
    }

    return sortVisitors(stateVisitors);
}

const updateVisitorOnPresenceMessage = (stateVisitors, newVisitor) => {
    let index = stateVisitors.findIndex((visitor) => visitor.visitor_id == newVisitor.visitor_id);

    if (newVisitor.visitor_status == "Online") {
        if (index == -1) {
            stateVisitors.push(newVisitor);
        } else {
            stateVisitors[index] = {...newVisitor};
        }
    } else {
        if (index > -1) {
            stateVisitors.splice(index, 1);
        }
    }

    return sortVisitors(stateVisitors);
}

const updateVisitorsAfterNewMessage = (stateVisitors, newMessage, stateCurrentUser, currentTab) => {
    let index = stateVisitors.findIndex((visitor) => visitor.visitor_id == newMessage.message.visitorId);

    let ifNew = false;
    let playAudio = false;
    if (index == -1) {
        ifNew = true;
        playAudio = true;
        let newVisitor = newMessage.visitor;
        newVisitor.messages = [newMessage.message]
        stateVisitors.push(newVisitor);
    }

    if (!ifNew) {
        let messageIndex = stateVisitors[index].messages.findIndex(message => message.message_id == newMessage.message.message_id);

        if (messageIndex == -1) {
            Object.keys(newMessage.visitor).forEach((key) => {
                stateVisitors[index][key] = newMessage.visitor[key];
            });
            stateVisitors[index].messages.push(newMessage.message);
            playAudio = true;
        } else {

            // if (typeof stateVisitors[index].messages[messageIndex].sessionId == "undefined") {
            //     playAudio = true;
            // }
            let oldMessages = stateVisitors[index].messages;
            stateVisitors[index] = {...newMessage.visitor, messages: [...oldMessages]};
            stateVisitors[index].messages[messageIndex] = {...newMessage.message};
            console.log("Duplicate message");
        }

        stateVisitors[index].messages = stateVisitors[index].messages.sort((a, b) => {
            let aUpdated = new Date(a.updated_at).getTime();
            let bUpdated = new Date(a.updated_at).getTime();
            if (aUpdated > bUpdated) return 1;
            if (aUpdated < bUpdated) return -1;
            return 0;
        });
    }

    if (playAudio && currentTab == 1) {
        // console.log("stateCurrentUser", stateCurrentUser, stateCurrentUser.user.email, newMessage.visitor.visitor_agent.email);

        if (newMessage.message && newMessage.message.message_type == "new_message") {
            if (newMessage.message && newMessage.message.message_status == "I") {
                new Audio("/audio/incoming_audio.mp3").play();
            } else {
                if (stateCurrentUser && stateCurrentUser.user && stateCurrentUser.user.email && 
                    newMessage.visitor && newMessage.visitor.visitor_agent && newMessage.visitor.visitor_agent.email &&
                    newMessage.visitor.visitor_agent.email == stateCurrentUser.user.email) {
                        new Audio("/audio/send.mp3").play();
                    } else {
                        new Audio("/audio/incoming_audio.mp3").play();
                    }
            }
        }
    }
    return sortVisitors(stateVisitors);
}

const updateVisitorsAfterTicketUpdate = (stateVisitors, newMessage) => {
    let index = stateVisitors.findIndex((visitor) => visitor.visitor_id == newMessage.visitor.visitor_id);

    if (index > -1) {
        Object.keys(newMessage.visitor).forEach((key) => {
            stateVisitors[index][key] = newMessage.visitor[key];
        });
    }
    return sortVisitors(stateVisitors);
}

const sortVisitors = (stateVisitors) => {

    stateVisitors = stateVisitors.sort((a, b) => {
        let aDate = new Date(a.updated_at);
        let bDate = new Date(b.updated_at);

        if (aDate > bDate) return -1;
        if (aDate < bDate) return 1;
        return 0;
    });

    return stateVisitors;
}

export default inbox;
