import { notification, message, Row, Col } from "antd";

const MultipleErrorComponent = ({ errors }) => {
   return (
      <>
         <Row>
            {errors &&
               errors.map((error, index) => (
                  <Col span={24}>
                     <strong>{error}</strong>
                  </Col>
               ))}
         </Row>
      </>
   );
};

/**
 * it show toast notification
 * @param {
 * res:show success or error toast depending upon response status ans message
 * successMessage: shows custom success message
 * errorMessage:shows custom error message
 * } param0
 * @returns
 */

export const toastNotification = ({
   res = null,
   successMessage = null,
   errorMessage = null,
}) => {
   if (successMessage) {
      notification.success({
         message: successMessage,
      });

      if (errorMessage) {
         notification.error({
            message: errorMessage,
         });
      }
      return;
   }
   if (errorMessage) {
      notification.error({
         message: errorMessage,
      });
      return;
   }

   if (res) {

      if (res.status === "success") {
         if (res.message !== null) {
            notification.success({
               message: res.message
            })
         } else {
            notification.success({
               message: "Saved Successfully"
            })
         }

      }

      if ((typeof res.status != "undefined" && res.status != "success") || (typeof res.success != "undefined" && !res.success)) {

         if (res.data && res.data.errors) {
            message.error({
               content: <MultipleErrorComponent errors={res.data.errors} />,
               duration: 10
            });
            return;
         }

         if (res.data && res.data.statusCode == 400) {
            notification.error({
               message: res.data.message
            });
            return;
         }
         if (res.message != null) {
            notification.error({
               message: res.message
            });
            return;
         }
      }
   }
}
