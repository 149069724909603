import {
  SHOW_LOADING,
  SET_NODE,
  SET_BOT_FLOW,
  SET_STORY,
  SET_BOT_ACCOUNTS,
  SAVE_BOT_ACCOUNTS,
  RESET_BOTFLOW,
} from "../constants/BotFlow";

const initState = {
  loading: false,
  nodes: [],
  sessionVariables: [],
  accounts: [],
  selectedStory: "root",
  userBotAccounts: [],
};

const botflow = (state = initState, action) => {
  // console.log("ACTION", action);
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_NODE: {
      return {
        ...state,
        nodes: action.payload,
      };
    }
    case RESET_BOTFLOW: {
      return {
        loading: false,
        nodes: [],
        sessionVariables: [],
        accounts: [],
        selectedStory: "root",
        userBotAccounts: [],
      };
    }
    case SET_STORY: {
      return {
        ...state,
        selectedStory: action.payload,
      };
    }
    case SET_BOT_FLOW: {
      return {
        ...state,
        nodes: action.payload,
      };
    }
    case SET_BOT_ACCOUNTS: {
      return {
        ...state,
        accounts: action.payload,
      };
    }
    case SAVE_BOT_ACCOUNTS: {
      return {
        ...state,
        userBotAccounts: action.payload,
      };
    }
    default:
      return state;
  }
};

export default botflow;
