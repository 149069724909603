import {
  SHOW_LOADING,
  TEAM_LIST_SUCCESS,
  CREATE_NEW_TEAM,
  NEW_TEAM_FAIL,
  LOAD_USERS,
  CANNED_MESSAGE_LIST_SUCCESS,
  LOAD_CANNED_MESSAGE,
  CREATE_CANNED_MESSAGE,
  LOAD_CANNED_MESSAGE_CURRENT,
  CANNED_MESSAGE_CURRENT,
  GET_BUSINESS_HOUR_LIST,
  LOAD_BUSINESS_HOUR_LIST,
  CREATE_BUSINESS_HOUR,
  UPDATE_BUSINESS_HOUR,
  UPDATE_CANNED_GROUP,
  CREATE_CANNED_GROUP,
  UPDATE_CANNED_MESSAGE,
  LOAD_CANNED_GROUP,
  CANNED_GROUP_LIST_SUCCESS,
  UPDATE_NEW_TEAM,
  UPDATE_ORG,
  GET_ORG,
  SAVE_ORG,
  BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM,
  LOAD_BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM,
  SAVE_SETTING,
  GET_SETTING,
  CREATE_BULK_USER,
  GET_BULK_USER,
  SAVE_BULK_USER,
  GET_USER_TEAM,
  SAVE_USER_TEAM,
  UPDATE_USER,
  LOGOUT_USER,
  UPDATE_SETTING,
  CREATE_CSAT_CONFIG,
  GET_CSAT_CONFIG,
  SAVE_CSAT_CONFIG,
  GET_ACCESS,
  UPDATE_ACCESS,
  SAVE_ACCESS,
  GET_TAGS,
  ADD_TAGS,
  UPDATE_TAGS,
  SAVE_TAGS,
  ADD_SUB_TAGS,
  UPDATE_SUB_TAGS,
  GET_USER_TEAM_METADATA,
  SAVE_USER_TEAM_METADATA,
  RESET_USERSANDTEAMS,
} from "../constants/UsersAndTeam";

const initState = {
  loading: false,
  message: "",
  teams: [],
  users: [],
  cannedMessages: [],
  cannedMessagesCurrent: [],
  businessHours: [],
  cannedGroups: [],
  currentOrg: null,
  currentBhourTeam: [],
  setting: null,
  currentUserTeam: [],
  bulkUsers: [],
  csatConfig: null,
  access: null,
  tags: [],
  userTeamMetadata: {
    allowed: 0,
  },
};

const usersAndTeam = (state = initState, action) => {
  switch (action.type) {
    case TEAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        teams: action.payload,
      };
    case CANNED_MESSAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESET_USERSANDTEAMS:
      return {
        loading: false,
        message: "",
        teams: [],
        users: [],
        cannedMessages: [],
        cannedMessagesCurrent: [],
        businessHours: [],
        cannedGroups: [],
        currentOrg: null,
        currentBhourTeam: [],
        setting: null,
        currentUserTeam: [],
        bulkUsers: [],
        csatConfig: null,
        access: null,
        tags: [],
        userTeamMetadata: {
          allowed: 0,
        },
      };
    case GET_SETTING:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SETTING:
      return {
        ...state,
        loading: false,
      };
    case SAVE_SETTING:
      return {
        ...state,
        loading: false,
        setting: action.payload,
      };
    case GET_ACCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ACCESS:
      return {
        ...state,
        loading: false,
      };
    case SAVE_ACCESS:
      return {
        ...state,
        loading: false,
        access: action.payload,
      };
    case CANNED_GROUP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CANNED_MESSAGE_CURRENT:
      return {
        ...state,
        loading: false,
      };
    case LOAD_CANNED_MESSAGE:
      return {
        ...state,
        loading: false,
        cannedMessages: action.payload,
      };
    case LOAD_CANNED_GROUP:
      return {
        ...state,
        loading: false,
        cannedGroups: action.payload,
      };
    case LOAD_CANNED_MESSAGE_CURRENT:
      return {
        ...state,
        loading: false,
        cannedMessagesCurrent: action.payload,
      };
    case CREATE_NEW_TEAM:
      return {
        ...state,
        loading: false,
      };
    case CREATE_BULK_USER:
      return {
        ...state,
        loading: false,
      };
    case GET_BULK_USER:
      return {
        ...state,
        loading: false,
      };
    case SAVE_BULK_USER:
      return {
        ...state,
        loading: false,
        bulkUsers: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        loading: false,
      };
    case GET_USER_TEAM:
      return {
        ...state,
        loading: false,
      };
    case SAVE_USER_TEAM:
      return {
        ...state,
        loading: false,
        currentUserTeam: action.payload,
      };
    case UPDATE_NEW_TEAM:
      return {
        ...state,
        loading: false,
      };
    case CREATE_CANNED_GROUP:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CANNED_GROUP:
      return {
        ...state,
        loading: false,
      };
    case CREATE_CANNED_MESSAGE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CANNED_MESSAGE:
      return {
        ...state,
        loading: false,
      };
    case NEW_TEAM_FAIL:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };

    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_USERS: {
      return {
        ...state,
        teams: action.payload,
      };
    }
    case GET_BUSINESS_HOUR_LIST:
      return {
        ...state,
        loading: false,
      };
    case LOAD_BUSINESS_HOUR_LIST: {
      return {
        ...state,
        businessHours: action.payload,
      };
    }
    case CREATE_BUSINESS_HOUR:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_BUSINESS_HOUR:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ORG:
      return {
        ...state,
        loading: false,
      };
    case GET_ORG:
      return {
        ...state,
        loading: false,
      };
    case SAVE_ORG:
      return {
        ...state,
        loading: false,
        currentOrg: action.payload,
      };
    case BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM:
      return {
        ...state,
        loading: false,
      };
    case LOAD_BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM:
      return {
        ...state,
        loading: false,
        currentBhourTeam: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        loading: false,
      };
    case CREATE_CSAT_CONFIG:
      return {
        ...state,
        loading: false,
      };
    case GET_CSAT_CONFIG:
      return {
        ...state,
        loading: false,
      };
    case SAVE_CSAT_CONFIG:
      return {
        ...state,
        loading: false,
        csatConfig: action.payload,
      };
    case GET_TAGS:
      return {
        ...state,
        loading: false,
      };
    case ADD_TAGS:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_TAGS:
      return {
        ...state,
        loading: true,
      };
    case SAVE_TAGS:
      return {
        ...state,
        loading: false,
        tags: [...action.payload],
      };
    case ADD_SUB_TAGS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SUB_TAGS:
      return {
        ...state,
        loading: false,
      };
    case GET_USER_TEAM_METADATA:
      return {
        ...state,
        loading: true,
      };
    case SAVE_USER_TEAM_METADATA:
      return {
        ...state,
        userTeamMetadata: { ...action.payload },
      };
    default:
      return state;
  }
};

export default usersAndTeam;
