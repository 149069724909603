import {
    UPDATE_API_STATUS,
UPDATE_MENU_KEY,
CURRENT_SCREEN_TITLE,
SEARCH_BOTS,
GET_CRM_SCHEMA,
SAVE_CRM_SCHEMA,
UPDATE_CRM_SCHEMA,
RESET_COMMON,
SAVE_SELECTED_BOT
} from '../constants/common';


export const updateMenu = (value) => {
	return {
		type: UPDATE_MENU_KEY,
		payload: value
	}
};
export const updateApiStatus = ({status, message}) => {
	return {
		type: UPDATE_API_STATUS,
        payload: {status, message}
	}   
};

export const resetCommon = () => {
	return {
		type: RESET_COMMON,
        
	}   
};

export const updateCurrentTitle = (title) => {
	return {
		type: CURRENT_SCREEN_TITLE,
		payload: title
	}
}

export const searchBots = (searchText) => {
	return {
		type: SEARCH_BOTS,
		payload: searchText
	}
}

export const getCrmSchema = (title) => {
	return {
		type: GET_CRM_SCHEMA
	}
}

export const saveCrmSchema = (data) => {
	return {
		type: SAVE_CRM_SCHEMA,
		payload: data
	}
}

export const updateCrmSchema = (data) => {
	return {
		type: UPDATE_CRM_SCHEMA,
		payload: data
	}
}

export const saveSelectedBot = (data) => {
	return {
		type: SAVE_SELECTED_BOT,
		payload: data
	}
}
