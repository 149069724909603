import React from "react";
import unauthImg from "../../assets/images/unauth.png";
function NoAccess(props) {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
                flexDirection:"column"
                ,
                marginTop:"10%"
			}}>
			<div>
				<h1>You Don't have access to this page</h1>
			</div>
			<div>
				<img src={unauthImg} alt="No access" />
			</div>
		</div>
	);
}

export default NoAccess;
