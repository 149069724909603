import { combineReducers } from "redux";

import inbox from "./inbox";
import Auth from "./Auth";
import UsersAndTeam from "./UsersAndTeam";
import botFlow from "./BotFlow";
import common from "./common"
const reducers = combineReducers({

  inbox,
  auth: Auth,
  UsersAndTeam,
  botFlow,
  common

});

export default reducers;
