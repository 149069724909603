import {
	all,
	takeEvery,
	put
} from "redux-saga/effects";
import {
	saveSocketInfo,
	saveMessages,
	saveVisitors,
	saveCannedResponses,
	updateMarkRead,
	saveBotAgents,
	saveAgentAvailability,
	saveUserInboxTeams,
	saveTagsInbox,
	saveUnread,
	saveLiveOnlineVisitorCount,
} from "../actions/inbox";
import {
	FETCH_SOCKET_INFO,
	GET_VISITORS,
	GET_MESSAGES,
	GET_CANNED_RESPONSES,
	MARK_READ,
	FETCH_ONE_VISITOR,
	GET_BOT_AGENTS,
	GET_AGENT_AVAILABILITY,
	UPDATE_AGENT_AVAILABILITY,
	EVENT_AGENT_MESSAGE,
	UPDATE_VISITORS,
	CLOSE_CASE,
	GET_USER_INBOX_TEAMS,
	AGENT_REASSIGN,
	GET_TAGS_INBOX,
	GET_UNREAD,
	GET_INBOX_BOT_ACCOUNTS,
	LIVE_VISITOR_TRANSFER,
	GET_LIVE_ONLINE_VISITOR_COUNT,
} from "../constants/inbox";
import ApiService from "../../Services/ApiService";
import { toastNotification } from "../../utils/toastNotification";

function* getSocketInfo() {
	yield takeEvery(FETCH_SOCKET_INFO, function* (action) {
		try {
			const res = yield ApiService.post("/blackwidow/socket-info", {}, {});
			if (res && res.status == "success") {
				yield put(saveSocketInfo(action.payload.botStrId, res.data));
			}
		} catch (err) {

		}
	});
}

function* getVisitors() {
	yield takeEvery(GET_VISITORS, function* (action) {
		try {
			let endpoint = "/visitors";

			if (action.payload.differentRoute) {
				endpoint = action.payload.differentRoute;
			}
			const res = yield ApiService.get(`/ironman${endpoint}`, {}, {
				filter: {
					where: action.payload.where,
					fields: {
						visitor_bot_id: false
					},
					order: "updated_at desc",
					...action.payload.pagination
				},
				// show_unread: true
			});
			if (res && res.status == "success") {
				yield put(saveVisitors(res.data));
			}
		} catch (err) {

		}
	});
}

function* getMessages() {
	yield takeEvery(GET_MESSAGES, function* (action) {
		try {
			const res = yield ApiService.get("/ironman/messages", {}, {
				filter: {
					where: {
						visitorId: action.payload.visitorId
					}
				},
				fields: {
					message_bot_id: false
				},
				onSelection:true
			});
			if (res && res.status == "success") {
				yield put(saveMessages(action.payload.visitorId, res.data));
			}
		} catch (err) {

		}
	});
}

function* getCannedResponses() {
	yield takeEvery(GET_CANNED_RESPONSES, function* (action) {
		try {
			const res = yield ApiService.get("/hulk/api/v1/canned-responses-inbox", {}, {});
			if (res && res.status == "success") {
				yield put(saveCannedResponses(res.data));
			}
		} catch (err) {

		}
	});
}

function* markRead() {
	yield takeEvery(MARK_READ, function* (action) {
		try {
			// const res = yield ApiService.patch(`/ironman/messages`, {}, { message_seen: true }, {
			// 	where: {
			// 		visitorId: action.payload.visitorId,
			// 		message_seen: false
			// 	}
			// });
			yield put(updateMarkRead(action.payload.visitorId));
		} catch (err) {
			toastNotification({errorMessage:"Internal Server Error"});
		}
	});
}

function* fetchOneVisitor() {
	yield takeEvery(FETCH_ONE_VISITOR, function* (action) {
		try {
			const res = yield ApiService.get(`/ironman/visitors/${action.payload.visitorId}`, {}, {});
			if (res && res.status == "success") {
				yield put(saveVisitors([res.data]));
			}
		} catch (err) {

		}
	});
}

function* getBotAgents() {
	yield takeEvery(GET_BOT_AGENTS, function* (action) {
		try {
			const res = yield ApiService.get(`/hulk/api/v1/bot-agents`, {}, {});
			if (res && res.status == "success") {
				yield put(saveBotAgents(res.data));
			}
		} catch (err) {

		}
	});
}

function* getAgentAvailability() {
	yield takeEvery(GET_AGENT_AVAILABILITY, function* (action) {
		try {
			const res = yield ApiService.get(`/hulk/api/v1/availability`, {}, {});
			if (res && res.status == "success") {
				yield put(saveAgentAvailability(res.data));
			}
		} catch (err) {

		}
	});
}

function* updateAgentAvailability() {
	yield takeEvery(UPDATE_AGENT_AVAILABILITY, function* (action) {
		try {
			const res = yield ApiService.post(`/hulk/api/v1/availability`, {}, {
				status: action.payload
			});

			toastNotification({res})

			if (res && res.status == "success") {
				yield put({ type: GET_AGENT_AVAILABILITY });
			}
		} catch (err) {
			toastNotification({errorMessage:"Internal Server Error"});
		}
	});
}

function* sendAgentMessage() {
	yield takeEvery(EVENT_AGENT_MESSAGE, function* (action) {
		try {
			const res = yield ApiService.post(`/ironman/api/v1/agentMessage/text`, {}, action.payload);
		} catch (err) {

		}
	});
}

function* updateVisitor() {
	yield takeEvery(UPDATE_VISITORS, function* (action) {
		try {
			const res = yield ApiService.patch(`/ironman/visitors/${action.payload.id}`, {}, action.payload.body);
			toastNotification({res: null, successMessage: "Updated details successfully"});
		} catch (err) {
			toastNotification({errorMessage:"Internal Server Error"});
		}
	});
}

function* closeCase() {
	yield takeEvery(CLOSE_CASE, function* (action) {
		try {
			const res = yield ApiService.post(`/ironman/api/v1/updateTicketDetails`, {}, action.payload.body);
			toastNotification({res})
		} catch (err) {
			toastNotification({errorMessage:"Internal Server Error"});
		}
	});
}

function* agentReassign() {
	yield takeEvery(AGENT_REASSIGN, function* (action) {
		try {
			const res = yield ApiService.post(`/ironman/api/v1/updateTicketDetails`, {}, action.payload.body);
			toastNotification({res})
		} catch (err) {
			toastNotification({errorMessage:"Internal Server Error"});
		}
	});
}

function* liveVisitorTransfer() {
	yield takeEvery(LIVE_VISITOR_TRANSFER, function* (action) {
		try {
			const res = yield ApiService.post(`/ironman/api/v1/transferLiveVisitor`, {}, action.payload.body);
			toastNotification({res})
		} catch (err) {
			toastNotification({errorMessage:"Internal Server Error"});
		}
	});
}

function* getUserInboxTeams() {
	yield takeEvery(GET_USER_INBOX_TEAMS, function* (action) {
		try {
			const res = yield ApiService.get(`/hulk/api/v1/user-teams-inbox`, {}, {});
			if (res && res.status == "success") {
				yield put(saveUserInboxTeams(res.data));
			}
		} catch (err) {

		}
	});
}

function* getUserInboxTags() {
	yield takeEvery(GET_TAGS_INBOX, function* (action) {
		try {
			const res = yield ApiService.get(`/hulk/api/v1/tags-inbox`, {}, {});
			if (res && res.status == "success") {
				yield put(saveTagsInbox(res.data));
			}
		} catch (err) {

		}
	});
}

function* getLiveOnlineVisitorCount() {
	yield takeEvery(GET_LIVE_ONLINE_VISITOR_COUNT, function* (action) {
		try {
			const res = yield ApiService.get(`/ironman/presence_count`, {}, {});
			if (res && res.status == "success") {
				yield put(saveLiveOnlineVisitorCount(res.data));
			}
		} catch (err) {

		}
	});
}


function* getUnread() {
	yield takeEvery(GET_UNREAD, function* (action) {
		try {
			const res = yield ApiService.get(`/ironman/messages/aggregate`, {}, {
				where: {
					visitor_id: {
						inq: action.payload
					},
					message_seen: false
				},
				group: [
					{
						$group: {
							_id: "$visitor_id",
							count: { $sum: 1}
						}
					}
				]
			});
			if (res && res.status == "success") {
				yield put(saveUnread(res.data));
			}
		} catch (err) {

		}
	});
}


export default function* rootSaga() {
	yield all([
		getSocketInfo(),
		getVisitors(),
		getMessages(),
		getCannedResponses(),
		markRead(),
		fetchOneVisitor(),
		getBotAgents(),
		getAgentAvailability(),
		updateAgentAvailability(),
		sendAgentMessage(),
		updateVisitor(),
		closeCase(),
		getUserInboxTeams(),
		agentReassign(),
		getUserInboxTags(),
		getUnread(),
		liveVisitorTransfer(),
		getLiveOnlineVisitorCount(),
	]);
}
