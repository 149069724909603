import {
  SHOW_LOADING,
  TEAM_LIST_SUCCESS,
  CREATE_NEW_TEAM,
  NEW_TEAM_FAIL,
  GET_TEAM_LIST,
  LOAD_USERS,
  LOAD_CANNED_MESSAGE,
  CANNED_MESSAGE_LIST_SUCCESS,
  CREATE_CANNED_MESSAGE,
  CANNED_MESSAGE_CURRENT,
  LOAD_CANNED_MESSAGE_CURRENT,
  CREATE_BUSINESS_HOUR,
  BUSINESS_HOUR_CURRENT,
  LOAD_BUSINESS_HOUR_CURRENT,
  GET_BUSINESS_HOUR_LIST,
  LOAD_BUSINESS_HOUR_LIST,
  UPDATE_BUSINESS_HOUR,
  CANNED_GROUP_LIST_SUCCESS,
  LOAD_CANNED_GROUP,
  UPDATE_CANNED_GROUP,
  CREATE_CANNED_GROUP,
  UPDATE_CANNED_MESSAGE,
  UPDATE_NEW_TEAM,
  UPDATE_ORG,
  GET_ORG,
  SAVE_ORG,
  BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM,
  LOAD_BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM,
  SAVE_SETTING,
  GET_SETTING,
  CREATE_BULK_USER,
  GET_BULK_USER,
  SAVE_BULK_USER,
  GET_USER_TEAM,
  SAVE_USER_TEAM,
  UPDATE_USER,
  LOGOUT_USER,
  UPDATE_SETTING,
  GET_CSAT_CONFIG,
  CREATE_CSAT_CONFIG,
  SAVE_CSAT_CONFIG,
  GET_ACCESS,
  UPDATE_ACCESS,
  SAVE_ACCESS,
  GET_TAGS,
  SAVE_TAGS,
  ADD_TAGS,
  ADD_SUB_TAGS,
  UPDATE_SUB_TAGS,
  UPDATE_TAGS,
  GET_USER_TEAM_METADATA,
  SAVE_USER_TEAM_METADATA,
  RESET_USERSANDTEAMS,
} from "../constants/UsersAndTeam";

import apiService from "../../Services/ApiService";

export const getTeamList = () => {
  return {
    type: GET_TEAM_LIST,
  };
};

export const resetUserAndTeams = () => {
  return{
    type: RESET_USERSANDTEAMS
  }
}

export const saveUsers = (users) => {
  return {
    type: LOAD_USERS,
    payload: users,
  };
};
export const getBulkUsers = () => {
  return {
    type: GET_BULK_USER,
  };
};
export const saveBulkUsers = (users) => {
  return {
    type: SAVE_BULK_USER,
    payload: users,
  };
};
export const getUserTeam = (id) => {
  return {
    type: GET_USER_TEAM,
    payload: id
  };
};
export const saveUserTeam = (users) => {
  return {
    type: SAVE_USER_TEAM,
    payload: users,
  };
};
export const updateUser = (body, id) => {
  return {
    type: UPDATE_USER,
    payload: { body, id },
  };
};

export const createNewTeam = (team) => {
  return {
    type: CREATE_NEW_TEAM,
    payload: team,
  };
};
export const updateTeam = (body, id) => {
  return {
    type: UPDATE_NEW_TEAM,
    payload: { body, id },
  };
};
export const showTeamMessage = (message) => {
  return {
    type: NEW_TEAM_FAIL,
    message,
  };
};
export const getCannedTeamList = () => {
  return {
    type: CANNED_MESSAGE_LIST_SUCCESS,
  };
};
export const getCannedGroupList = () => {
  return {
    type: CANNED_GROUP_LIST_SUCCESS,
  };
};
export const getCannedCurrentTeam = (id) => {
  return {
    type: CANNED_MESSAGE_CURRENT,
    payload: id,
  };
};
export const saveCannedMessages = (cannedMessages) => {
  return {
    type: LOAD_CANNED_MESSAGE,
    payload: cannedMessages,
  };
};
export const saveCannedGroups = (cannedGroup) => {
  return {
    type: LOAD_CANNED_GROUP,
    payload: cannedGroup,
  };
};
export const saveCannedMessagesCurrent = (cannedMessages) => {
  return {
    type: LOAD_CANNED_MESSAGE_CURRENT,
    payload: cannedMessages,
  };
};
export const createNewCannedMessage = (body) => {
  return {
    type: CREATE_CANNED_MESSAGE,
    payload: body,
  };
};
export const updateCannedMessage = (body, id) => {
  return {
    type: UPDATE_CANNED_MESSAGE,
    payload: { body, id },
  };
};
export const getBussinessHourList = () => {
  return {
    type: GET_BUSINESS_HOUR_LIST,
  };
};
export const createBussinessHourList = (body) => {
  return {
    type: CREATE_BUSINESS_HOUR,
    payload: body,
  };
};
export const updateBussinessHour = (body, id) => {
  return {
    type: UPDATE_BUSINESS_HOUR,
    payload: { body, id },
  };
};
export const saveBussinessHourList = (bHours) => {
  return {
    type: LOAD_BUSINESS_HOUR_LIST,
    payload: bHours,
  };
};
export const createCannedGroup = (body) => {
  return {
    type: CREATE_CANNED_GROUP,
    payload: body,
  };
};
export const updateCannedGroup = (body, id) => {
  return {
    type: UPDATE_CANNED_GROUP,
    payload: { body, id },
  };
};
export const updateOrg = (body, orgStrId) => {
  return {
    type: UPDATE_ORG,
    payload: { body, orgStrId },
  };
};
export const getOrg = (orgStrId) => {
  return {
    type: GET_ORG,
    payload: orgStrId
  };
};

export const saveOrg = (org) => {
  return {
    type: SAVE_ORG,
    payload: org,
  };
};
export const getBhourTeam = () => {
  return {
    type: BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM,
  };
};

export const saveBhourTeam = (btm) => {
  return {
    type: LOAD_BUSINESS_HOUR_CURRENT_AVAILABLE_TEAM,
    payload: btm,
  };
};
export const getSetting = ({history}) => {
  return {
    type: GET_SETTING,
    payload: history
  };
};
export const updateSetting = (body) => {
  return {
    type: UPDATE_SETTING,
    payload: body,
  };
};

export const saveSetting = (setting) => {
  return {
    type: SAVE_SETTING,
    payload: setting,
  };
};
export const getAccess = (xCurrUrl) => {
  return {
    type: GET_ACCESS,
    payload: xCurrUrl
  };
};
export const updateAccess = (body) => {
  return {
    type: UPDATE_ACCESS,
    payload: body,
  };
};

export const saveAccess = (access) => {
  return {
    type: SAVE_ACCESS,
    payload: access,
  };
};
export const createUserBulk = (data) => {
  return {
    type: CREATE_BULK_USER,
    payload: data
  };
};
export const getUserBulk = (data) => {
  return {
    type: GET_BULK_USER,
    payload: data
  };
};
export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: history
  };
};
export const getCsat = () => {
  return {
    type: GET_CSAT_CONFIG,
  };
};
export const createCsat = (body) => {
  return {
    type: CREATE_CSAT_CONFIG,
    payload: body,
  };
};

export const saveCsat = (csat) => {
  return {
    type: SAVE_CSAT_CONFIG,
    payload: csat,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const getTags = () => {
  return {
    type: GET_TAGS
  };
}

export const addTags = (body) => {
  return {
    type: ADD_TAGS,
    payload: body
  };
}

export const updateTags = (id, body) => {
  return {
    type: UPDATE_TAGS,
    payload: {id, body}
  };
}

export const saveTags = (tags) => {
  return {
    type: SAVE_TAGS,
    payload: tags
  };
}

export const addSubTag = (body) => {
  return {
    type: ADD_SUB_TAGS,
    payload: body
  };
}

export const updateSubTag = (id, body) => {
  return {
    type: UPDATE_SUB_TAGS,
    payload: {id, body}
  };
}

export const getUsersAndTeamMetadata = () => {
  return {
    type: GET_USER_TEAM_METADATA
  }
}

export const saveUsersAndTeamMetadata = (data) => {
  return {
    type: SAVE_USER_TEAM_METADATA,
    payload: data
  }
}