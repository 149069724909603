import React, { lazy, Suspense, useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import { useSelector } from "react-redux";
import Loading from "./components/shared-components/Loading";
import NoAccess from "./components/NoAccess/NoAccess";
const Home = lazy(() => import(`./Home`));

const ProtectedRoute = ({ path }) => {
    return <Route path={path} component={Home} />;
};

function Routes() {
    const [showLoaderCount, setShowLoaderCount] = useState(0);
    const { authenticated = true } = useSelector((state) => state.auth);
    const { loading: authLoading } = useSelector((state) => state.auth);
    // const { loading: organizationLoading } = useSelector((state) => state.organization);
    // const { loading: botLoading } = useSelector((state) => state.bot);
    const { loading: UsersAndTeamLoading } = useSelector(
        (state) => state.UsersAndTeam
    );
    const { loading: inboxLoading } = useSelector((state) => state.inbox);

    useEffect(() => {
        if (authLoading == true) {
            setShowLoaderCount(showLoaderCount + 1);
        } else if (authLoading == false) {
            setShowLoaderCount(showLoaderCount - 1);
        }
        // if (organizationLoading == true) {
        // 	setShowLoaderCount(showLoaderCount + 1);
        // } else if (organizationLoading == false) {
        // 	setShowLoaderCount(showLoaderCount - 1);
        // }
        // if (botLoading == true) {
        // 	setShowLoaderCount(showLoaderCount + 1);
        // } else if (botLoading == false) {
        // 	setShowLoaderCount(showLoaderCount - 1);
        // }
        if (UsersAndTeamLoading == true) {
            setShowLoaderCount(showLoaderCount + 1);
        } else if (UsersAndTeamLoading == false) {
            setShowLoaderCount(showLoaderCount - 1);
        }

        if (inboxLoading == true) {
            setShowLoaderCount(showLoaderCount + 1);
        } else if (inboxLoading == false) {
            setShowLoaderCount(showLoaderCount - 1);
        }
    }, [authLoading, UsersAndTeamLoading, inboxLoading]);

    return (
        <>
            {showLoaderCount > 0 && <Loading cover="content" />}
            <Suspense fallback={<Loading cover="content" />}>
                <Router>
                    <Switch>
                        <Redirect from="/" exact to="/home" />
                        <Route path="/forbidden" component={NoAccess} />
                        {authenticated && <ProtectedRoute path={"/"} />}
                    </Switch>
                </Router>
            </Suspense>
        </>
    );
}

// const mapStateToProps = ({ auth }) => {
// 	const { token } = auth;
// 	return { token };
// };

// export default connect(mapStateToProps)(Routes);
export default Routes;
